import React, {useState, useEffect, createRef, useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {
    findItem, getIndexOf, isSessionValid,
    parseMapCaretChars,
} from "../helpers/helpers";
import {
    BE_REST_API,
    downloadFile,
    loadAllFiles,
    loadAllFolders,
    loadAllUserGroups,
    loadFolder,
    loadFolderByFolderId,
    loadFolderContent,
    previewFile,
    removeFile,
    removeFolder,
    sendEmailToRecipients,
    sendFileToEmail,
    setDownloadProgress,
    setDownloadStatusAction,
    setSidebarWidth,
    updateFoldersSortIds,
} from "../store/actions/actions";
import Page, {SIDEBAR_WIDTH} from "../components/js/Page";
import {MIME_OPTIONS, USER_GROUP_TYPE} from "../constants/enums";
import Header from "../components/js/Header";
import "../css/Sharepoint.css"
import Input from "../components/js/Input";

import * as IoIcons from "react-icons/io";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";
import {PAGES} from "../App";
import {ReactComponent as IconEye} from "../assets/svg/Icon feather-eye.svg";
import {ReactComponent as IconMail} from "../assets/svg/Icon feather-mail.svg";
import {ReactComponent as IconDownload} from "../assets/svg/Icon feather-upload.svg";
import {ReactComponent as IconEdit} from "../assets/svg/Icon feather-edit-3.svg";
import {ReactComponent as IconSort} from "../assets/svg/Icon feather-sort.svg";
import {ReactComponent as IconPdf} from "../assets/svg/Icon metro-file-pdf.svg";
import {ReactComponent as IconVideo} from "../assets/svg/Icon feather-video.svg";
import {ReactComponent as IconFoto} from "../assets/svg/Icon feather-camera.svg";
import {ReactComponent as IconUrl} from "../assets/svg/Icon feather-link.svg";


import welcomeIcon from "../assets/img/welcome-banner-icon.png"
import background from "../assets/img/blur-background.png"

import Select from "react-select";
import DropdownFilter from "../components/js/DropdownFilter";
import Modal from "../components/js/Modal";
import ModalSharepoint from "../components/js/ModalSharepoint";
import FileUpload from "../components/js/FileUpload";
import FileUploadSharepoint from "../components/js/FileUploadSharepoint";
import * as CgIcons from "react-icons/cg";
import {Redirect} from "react-router";
import {ReactComponent as IconUser} from "../assets/svg/Icon user.svg";
import colors from "../constants/colors";
import Footer from "../components/js/Footer";
import DownloadProgress from "../components/js/DownloadProgress";
import ModalSharepointFileStats from "../components/js/ModalSharepointFileStats";
import ModalInfo from "../components/js/ModalInfo";

const ROOT_DIR_PATH = '/Uvodna stran';
//const ROOT_DIR_PATH = '/BBB';

const parseMimeFilter = (mime_type) => {
    if (mime_type.includes('png') || mime_type.includes('jpg') || mime_type.includes('gif')) {
        return MIME_OPTIONS.FOTO;
    } else if (mime_type.includes('mp4') || mime_type.includes('mpeg') || mime_type.includes('ogg') || mime_type.includes('mov')) {
        return MIME_OPTIONS.VIDEO;
    } else if (mime_type.includes('pdf')) {
        return MIME_OPTIONS.PDF;
    } else if (mime_type.includes('url')) {
        return MIME_OPTIONS.URL;
    }
}

const parseOrderByFilter = (filter) => {
    console.log("Filter", filter);
    switch (filter) {
        case 'po abecedi':
            return 'alphabetically';
        case 'starejši naprej':
            return 'oldest';
        case 'novejši naprej':
            return 'latest';
        case 'poljubno':
            return 'custom';
        default:
            return 'custom';
    }
}

const parseNameFromPath = (path) => {
    if (!path || path === '/') {
        return null;
    }
    return path.substring(path.lastIndexOf('/') + 1)
}


const isUserAdminInCurrentFolder = (foundUserGroup, username) => {
    console.log("FOUND USER GROUP", foundUserGroup, username);
    return foundUserGroup?.type === USER_GROUP_TYPE.ADMIN || foundUserGroup?.type === USER_GROUP_TYPE.SHAREPOINT_ADMIN || username === "plu.ceniki@gmail.com";
}
const isUserSubscriberInCurrentFolder = (foundUserGroup) => {
    //console.log("FOUND USER GROUP", foundUserGroup);
    return foundUserGroup?.type === USER_GROUP_TYPE.SUBSCRIBER;
}
const isUserCaretakerInCurrentFolder = (foundUserGroup) => {
    //console.log("FOUND USER GROUP", foundUserGroup);
    return foundUserGroup?.type === USER_GROUP_TYPE.CARETAKER;
}

/*
const isUserMemberOfAnyUserGroup = (usersUserGroupIds, contentsUserGroupIds, allUserGroups) => {
    if (!usersUserGroupIds || usersUserGroupIds === '' || usersUserGroupIds.length === 0) {
        usersUserGroupIds = [];
    } else {
        usersUserGroupIds = usersUserGroupIds.trim();
        usersUserGroupIds = usersUserGroupIds.split(',');
    }
    if (!contentsUserGroupIds || contentsUserGroupIds === '' || contentsUserGroupIds.length === 0) {
        contentsUserGroupIds = [];
    } else {
        contentsUserGroupIds = contentsUserGroupIds.trim();
        contentsUserGroupIds = contentsUserGroupIds.split(',');
    }

    let exists = false;
    for (let i = 0; i < usersUserGroupIds.length; i++) {
        for (let j = 0; j < contentsUserGroupIds.length; j++) {

            if (usersUserGroupIds[i] === contentsUserGroupIds[j]) {
                return findItem('user_group_id', usersUserGroupIds[i], allUserGroups);
            }

        }
    }
    if (!exists) {
        return null;
    } else {

    }
}
*/
const isUserMemberOfAnySubscriberGroups = (usersUserGroupIds, allUserGroups) => {
    if (!usersUserGroupIds || usersUserGroupIds === '' || usersUserGroupIds.length === 0) {
        usersUserGroupIds = [];
    } else {
        usersUserGroupIds = usersUserGroupIds.trim();
        usersUserGroupIds = usersUserGroupIds.split(',');
    }

    let exists = false;
    let userGroup = null;
    for (let i = 0; i < usersUserGroupIds.length; i++) {
        let foundUserGroup = findItem('user_group_id', usersUserGroupIds[i], allUserGroups);
        if (isUserSubscriberInCurrentFolder(foundUserGroup)) {
            console.log("FOUND SUBSCRIBER USER GROUP", foundUserGroup);
            return {type: USER_GROUP_TYPE.SUBSCRIBER};
        }
    }
    if (!exists) {
        return null;
    } else {
        return userGroup;
    }
}

const getCurrentFolderUserGroupsEmails = (usersUserGroupIds, contentsUserGroupIds, users) => {
    if (!contentsUserGroupIds || contentsUserGroupIds === '' || contentsUserGroupIds.length === 0) {
        contentsUserGroupIds = [];
    } else {
        contentsUserGroupIds = contentsUserGroupIds.trim();
        contentsUserGroupIds = contentsUserGroupIds.split(',');
    }

    let e = [];
    let e_tmp = [];
    for (let i = 0; i < contentsUserGroupIds.length; i++) {
        for (let j = 0; j < users.length; j++) {


            if (users[j].user_groups?.includes(contentsUserGroupIds[i])) {
                console.log("ALO", users[j].user_groups, contentsUserGroupIds[i]);

                let email = users[j].username?.toLowerCase();

                if (!findItem('address', email, e_tmp)) {
                    e.push(email);
                    e_tmp.push({address: email});
                }
            }
        }


    }
    console.log("EMAILS:", e);
    return e_tmp;
}

const isUserMemberOfAnyUserGroup = (usersUserGroupIds, contentsUserGroupIds, allUserGroups, userGroupTypeFilter, username) => {

    if (username === "plu.ceniki@gmail.com") {
        return {type: USER_GROUP_TYPE.ADMIN};
    }
    if (!usersUserGroupIds || usersUserGroupIds === '' || usersUserGroupIds.length === 0) {
        usersUserGroupIds = [];
    } else {
        usersUserGroupIds = usersUserGroupIds.trim();
        usersUserGroupIds = usersUserGroupIds.split(',');
    }
    if (!contentsUserGroupIds || contentsUserGroupIds === '' || contentsUserGroupIds.length === 0) {
        contentsUserGroupIds = [];
    } else {
        contentsUserGroupIds = contentsUserGroupIds.trim();
        contentsUserGroupIds = contentsUserGroupIds.split(',');
    }

    let exists = false;
    let userGroup = null;
    for (let i = 0; i < usersUserGroupIds.length; i++) {
        for (let j = 0; j < contentsUserGroupIds.length; j++) {

            if (!userGroupTypeFilter) {
                if (usersUserGroupIds[i] === contentsUserGroupIds[j]) {
                    return findItem('user_group_id', usersUserGroupIds[i], allUserGroups);
                }
            } else {
                if (usersUserGroupIds[i] === contentsUserGroupIds[j]) {
                    let foundUserGroup = findItem('user_group_id', usersUserGroupIds[i], allUserGroups);
                    exists = true;

                    if (isUserAdminInCurrentFolder(foundUserGroup, username)) {
                        return foundUserGroup;
                    } else if (isUserCaretakerInCurrentFolder(foundUserGroup) && (!userGroup || (!isUserAdminInCurrentFolder(userGroup, username)))) {
                        userGroup = foundUserGroup;
                    } else if (isUserSubscriberInCurrentFolder(foundUserGroup) && (!userGroup || (!isUserCaretakerInCurrentFolder(userGroup) && !isUserAdminInCurrentFolder(userGroup, username)))) {
                        userGroup = foundUserGroup;
                    } else {
                        return foundUserGroup;
                    }
                }
            }
        }
    }
    if (!exists) {
        return null;
    } else {
        return userGroup;
    }
}


export const getAllUsersAdminUserGroupsInFolder = (usersUserGroupIds, contentsUserGroupIds, allUserGroups) => {
    if (!usersUserGroupIds || usersUserGroupIds === '' || usersUserGroupIds.length === 0) {
        usersUserGroupIds = [];
    } else {
        usersUserGroupIds = usersUserGroupIds.trim();
        usersUserGroupIds = usersUserGroupIds.split(',');
    }
    if (!contentsUserGroupIds || contentsUserGroupIds === '' || contentsUserGroupIds.length === 0) {
        contentsUserGroupIds = [];
    } else {
        contentsUserGroupIds = contentsUserGroupIds.trim();
        contentsUserGroupIds = contentsUserGroupIds.split(',');
    }

    let updated = [];
    for (let i = 0; i < usersUserGroupIds.length; i++) {
        for (let j = 0; j < contentsUserGroupIds.length; j++) {


            if (usersUserGroupIds[i] === contentsUserGroupIds[j]) {
                let foundUserGroup = findItem('user_group_id', usersUserGroupIds[i], allUserGroups);

                if (isUserAdminInCurrentFolder(foundUserGroup, false)) {
                    updated.push(foundUserGroup);
                }
            }

        }
    }
    return updated;
}

export const getAllUserGroups = (contentsUserGroupIds) => {
    if (!contentsUserGroupIds || contentsUserGroupIds === '' || contentsUserGroupIds.length === 0) {
        contentsUserGroupIds = [];
    } else {
        contentsUserGroupIds = contentsUserGroupIds.trim();
        contentsUserGroupIds = contentsUserGroupIds.split(',');
    }
    return contentsUserGroupIds;
}


function Sharepoint(props) {
    let dispatch = useDispatch();

    const [c, setC] = useState(0);

    let token = useSelector(state => state.reducer.userToken);
    const user = useSelector(state => state.reducer.user);
    const sidebarWidth = useSelector(state => state.reducer.sidebarWidth);

    let users = useSelector(state => state.reducer.allUsers);

    let downloadProgress = useSelector(state => state.reducer.downloadProgress);

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [folder, setFolder] = useState(null);

    const [folders, setFolders] = useState([]);
    const [files, setFiles] = useState([]);

    const [allFiles, setAllFiles] = useState(null);
    const [allFolders, setAllFolders] = useState(null);
    const [mimeFilter, setMimeFilter] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);

    const [allUserGroups, setAllUserGroups] = useState(null);

    const [inputTimeout, setInputTimeout] = useState(null);
    const [nameInput, setNameInput] = useState(null);

    const [selectedFile, setSelectedFile] = useState(null);

    const [createDirDisplayModal, setCreateDirDisplayModal] = useState(false);
    const [updateDirDisplayModal, setUpdateDirDisplayModal] = useState(false);
    const [updateFileDisplayModal, setUpdateFileDisplayModal] = useState(false);
    const [editDescription, setEditDescription] = useState(false);

    const [userCurrentFolderUserRole, setUserCurrentFolderUserRole] = useState(null)

    const [editSortOrder, toggleEditSortOrder] = useState(false);
    const [folderSortIdPairs, setFolderSortIdPairs] = useState([]);
    const [selectedSortFolder, setSelectedSortFolder] = useState(null);

    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);

    const [openFileStatsModal, setOpenFileStatsModal] = useState(false);
    const [selectedStatsFileId, setSelectedStatsFileId] = useState(null);

    const [displayModalInfo, setDisplayModalInfo] = useState(false);
    const [modalInfoAction, setModalInfoAction] = useState(() => {
    });
    const [modalInfoText, setModalInfoText] = useState("");
    const [modalInfoTitle, setModalInfoTitle] = useState("");

    let clientSize = {x: document.documentElement.clientWidth, y: document.documentElement.clientHeight}
    let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
    const [mainHeight, setMainHeight] = useState(documentSize.y);

    const TIMEOUT_INTERVAL = 1;
    const SEARCH_BY_FIELDS = [
        'name'
    ];

    useEffect(() => {
        if (!!inputTimeout) {
            clearTimeout(inputTimeout);
            setInputTimeout(null);
        }
        setInputTimeout(setTimeout(() => {
            if (!nameInput || nameInput === '') {
                onLoadFolderContent(folder);
            } else {
                let filteredFiles = [];
                for (const file of files) {
                    for (const fieldName of SEARCH_BY_FIELDS) {
                        if (file.hasOwnProperty(fieldName) && !!file[fieldName] && parseMapCaretChars(file[fieldName]).toLowerCase().includes(nameInput.toLowerCase())) {
                            filteredFiles.push(file);
                            break;
                        }
                    }
                }
                setFiles(filteredFiles);
            }
        }, TIMEOUT_INTERVAL));
    }, [nameInput]);


    useEffect(() => {
        if (user && !allFiles && !allFolders) {
            dispatch(loadAllFolders(user.user_id, (res) => {
                console.log("Folders: ", res);
                setAllFolders(res);
            }, (err) => {
                console.warn("Error obtaining folders..", err)
            }));
            dispatch(loadAllFiles(user.user_id, (res) => {
                console.log("Files: ", res);
                setAllFiles(res);
            }, (err) => {
                console.warn("Error obtaining files..", err)
            }));
            dispatch(loadAllUserGroups(user.user_id, (res) => {
                console.log("User groups: ", res);
                setAllUserGroups(res);
            }, (err) => {
                console.warn("Error obtaining user groups..", err)
            }));
        }
        if (user && !folder) {
            console.log("User", user);
            dispatch(loadFolder(user.user_id, ROOT_DIR_PATH, (res) => {
                onLoadFolderContent(res);
            }, (err) => {
                console.warn("Error obtaining folders..", err)
            }));
        }
    }, [user])


    useEffect(() => {
        if (user && folder) {
            let userGroup = isUserMemberOfAnyUserGroup(user?.user_groups, folder?.user_groups, allUserGroups, true, user?.username);
            setUserCurrentFolderUserRole(userGroup);

            console.log("PWD", folder);
            console.log("User", user);
            console.log("USER CURRENT FOLDER USER GROUP:", userGroup);

            //onLoadFolderContent(folder);
        }
    }, [user, folder])

    useEffect(() => {
        console.log(selectedFilter);
        onLoadFolderContent(folder);
    }, [selectedFilter]);

    useEffect(() => {
        if (editSortOrder) {
            onLoadFolderContent(folder);
        } else {
            onUpdateFoldersCustomSorting();
        }
    }, [editSortOrder]);

    useEffect(() => {
        console.log("EDIT DESC", editDescription);
    }, [editDescription]);


    const renderModalInfo = () => {
        if (!displayModalInfo) {
            return <></>
        }
        return (
            <ModalInfo info title={modalInfoTitle} text={modalInfoText} actionClose={() => modalInfoAction()}/>
        );
    };

    const setModalValues = (text) => {
        setDisplayModalInfo(true);

        setModalInfoTitle(``);
        setModalInfoText(text);
        setModalInfoAction(() => {
            return async () => {
                setDisplayModalInfo(false);
            }
        })
    };

    const AddCurrentFolderToUserGroup = () => {

    };

    const selectSortFolder = (f) => {
        if (!selectedSortFolder) {
            setSelectedSortFolder(f)
        } else {
            let updated = [...folders];
            const idxRemove = getIndexOf('folder_id', selectedSortFolder.folder_id, folders);

            updated.splice(idxRemove, 1);
            const idx = getIndexOf('folder_id', f.folder_id, folders);

            updated.splice(idx, 0, selectedSortFolder);

            setSelectedSortFolder(null);
            setFolders(updated);
        }
    }

    const onUpdateFoldersCustomSorting = () => {
        if (!folders || folders.length === 0) {
            return;
        }
        let pairs = [];
        for (let i = 0; i < folders.length; i++) {
            pairs.push({folder_id: folders[i].folder_id, sort_id: i});
        }
        dispatch(updateFoldersSortIds(pairs, (res) => {
            console.log("Folders successfully updated..", res);

            setModalValues("Vrstni red direktorijev uspešno posodobljen!");
        }, (err) => {
            console.warn("Error updating folders..", err)
        }));
    };

    const onDeleteFolder = (folder_id) => {
        if (folders && folders.length > 0 || files && files.length > 0) {
            setModalValues("Pred brisanjem direktorija zagotovite, da je vsebina direktorija prazna..");
            return;
        }

        dispatch(removeFolder(folder_id, (res) => {
            console.log("File successfully deleted..", res);
            setModalValues("Direktorij uspešno izbrisan!");

            let parentDir = findItem("folder_id", folder.parent_folder_id, allFolders);
            console.log("PARENT", parentDir);
            console.log("ALL", allFolders);
            onLoadFolderContent(parentDir);

        }, (err) => {
            console.warn("Error deleting file..", err)
            setModalValues("Napaka pri brisanju direktorija..", err)
        }));
    };

    const onDeleteFile = (file_id) => {
        dispatch(removeFile(file_id, (res) => {
            console.log("File successfully deleted..", res);
            setModalValues("Datoteka uspešno izbrisana!");
            onLoadFolderContent(folder);

        }, (err) => {
            console.warn("Error deleting file..", err)
            setModalValues("Napaka pri brisanju datoteke..", err)
        }));
    };

    const onDownloadFile = (file_id) => {
        setLoadingSpinner(true);
        setIsDownloading(true);

        dispatch(setDownloadStatusAction(file_id, "Nalaganje datoteke.."));
        setDownloadStatus("Nalaganje datoteke..")

        dispatch(downloadFile(user.user_id, file_id, (res) => {
            setLoadingSpinner(false);
            dispatch(setDownloadStatusAction(file_id, "Prenos zaključen"));
            setDownloadStatus("Prenos zaključen")

            setTimeout(() => {
                setIsDownloading(false);
                dispatch(setDownloadProgress(file_id, 0));

            }, 3000)

            console.log("File successfully downloaded..", res);
        }, (err) => {
            setLoadingSpinner(false);
            dispatch(setDownloadStatusAction(file_id, "Prenos neuspešen"));
            setDownloadStatus("Prenos neuspešen")

            setTimeout(() => {
                setIsDownloading(false);
                dispatch(setDownloadProgress(file_id, 0));

            }, 5000)

            console.warn("Error downloading file..", err)
        }));
    };

    const onPreviewFile = (file_id) => {
        setLoadingSpinner(true);
        setIsDownloading(true);

        dispatch(setDownloadStatusAction(file_id, "Nalaganje datoteke.."));
        setDownloadStatus("Nalaganje datoteke..")


        dispatch(previewFile(file_id, user.user_id, (res) => {
            setLoadingSpinner(false);
            dispatch(setDownloadStatusAction(file_id, "Predogled zaključen"));
            setDownloadStatus("Predogled zaključen")

            setTimeout(() => {
                setIsDownloading(false);
                dispatch(setDownloadProgress(file_id, 0));

            }, 2000)

            console.log("File successfully downloaded..", res);
        }, (err) => {
            setLoadingSpinner(false);
            dispatch(setDownloadStatusAction(file_id, "Prenos neuspešen"));
            setDownloadStatus("Predogled neuspešen")

            setTimeout(() => {
                setIsDownloading(false);
                dispatch(setDownloadProgress(file_id, 0));

            }, 5000)

            console.warn("Error downloading file..", err)
        }));
    };


    const onSendFileToUserEmail = (file_id, email) => {
        dispatch(sendFileToEmail(file_id, user.user_id, email, (res) => {
            console.log("File successfully sent..", res);
            setModalValues(res);
        }, (err) => {
            console.warn("Error sending file..", err)
            setModalValues(err)
        }));
    };


    const onSendEmailToAllFolderUserGroupUsers = (fileName) => {
        console.log("ALO", user?.user_groups, folder?.user_groups, users);
        let recipients = getCurrentFolderUserGroupsEmails(user?.user_groups, folder?.user_groups, users);

        //recipients = [{address: "hiti.tadej@gmail.com"}]
        let content = `Nova datoteka '${fileName}' naložena v direktorij ${folder.path}`
        console.log("Content:" + content);
        dispatch(sendEmailToRecipients(recipients, content, (res) => {
            console.log("Email sent..", res);
        }, (err) => {
            console.warn("Error sending email..", err)
        }));
    };

    const onToggleFileStats = (file_id, open) => {
        if (open) {
            setSelectedStatsFileId(file_id);
        } else {
            setSelectedStatsFileId(null);
        }
        setOpenFileStatsModal(open);
    }


    const onLoadFolderContent = (folder, loadParent) => {
        console.log("load folder contents of folder:", folder);
        if (user && folder) {
            dispatch(loadFolderByFolderId(user.user_id, !loadParent ? folder.folder_id : folder.parent_folder_id, (res) => {
                console.log("Folder: ", res);
                setFolder(res);

                dispatch(loadFolderContent(user.user_id, !loadParent ? folder.folder_id : folder.parent_folder_id, !editSortOrder ? parseOrderByFilter(selectedFilter) : 'custom', (res) => {
                    console.log("Folders content: ", res);
                    //   setFolder(folder);
                    setFolders(res.folders);
                    setFiles(res.files);
                    if (!selectedFilter) {
                        //setSelectedFilter('starejši naprej')
                    }
                    stretchBackground(150);

                }, (err) => {
                    console.warn("Error obtaining folders content..", err)
                }));

            }, (err) => {
                console.warn("Error obtaining parent folder..", err)
            }));

        }
    };

    const onUploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("path", folder.path);

        try {
            const res = await axios.post(
                BE_REST_API + '/sharepoint/file/create',
                formData
            );

            //send email when upload complete to all subscribed to the folder
            //onSendEmailToAllFolderUserGroupUsers(file.name);

            console.log("FILE UPLOAD [RESPONSE]", res);
            setModalValues(`Datoteka uspešno naložena!`);
            onLoadFolderContent(folder);
        } catch (e) {
            console.log(e);
            setModalValues(`Napaka pri nalaganju:`, e);
        }
    };


    useEffect(() => {
        stretchBackground(1500);
    }, [])

    const ref = useRef();
    useEffect(() => {
        ref.current.scrollIntoView()
    }, []);

    const stretchBackground = (timeout) => {
        setTimeout(() => {
            let documentSize = {x: document.documentElement.clientWidth, y: document.documentElement.scrollHeight}
            setMainHeight(documentSize.y);
        }, timeout);
    }

    const onNavigateToFolder = (folder, loadParent) => {
        setC(c + 1);
        onLoadFolderContent(folder, loadParent)
    };

    const renderDownloadProgress = () => {
        if (!downloadProgress || downloadProgress?.length === 0) {
            return <></>
        }
        console.log("DL GET", downloadProgress);

        return <DownloadProgress left={`${sidebarWidth}px`} status={downloadStatus} progressQueue={downloadProgress}
                                 onLoading={loadingSpinner}/>
    }

    const renderRootWelcomeBanner = () => {
        if (folder?.path !== ROOT_DIR_PATH) {
            return <></>
        }
        return (
            <div className={"welcome-banner-container"}>
                <div className={"welcome-banner"}>
                    <img className={'sharepoint img-welcome'} src={welcomeIcon} alt="img"/>
                    <p className={'p-welcome-banner'}>Pozdravljeni na naši uvodni strani!</p>
                    <p className={'p-welcome-banner desc'}>Tukaj vidiš začetni direktorij.</p>
                </div>
            </div>

        );
    }

    const renderCollapsableDirDesc = () => {
        if (folder?.path === ROOT_DIR_PATH) {
            return <></>
        }
        return (
            <div className={"collapsable-banner-container"}>
                <div className={"collapsable-banner"}>
                    <img className={'sharepoint img-welcome small'} src={welcomeIcon} alt="img"/>
                    <p className={'p-welcome-banner desc'}>{parseMapCaretChars(folder?.description ? folder.description : '')}</p>
                </div>
            </div>

        );
    }

    const renderModalFileStats = () => {
        //console.log(folder);

        return (
            openFileStatsModal && selectedStatsFileId ?
                <ModalSharepointFileStats file_id={selectedStatsFileId}
                                          history
                                          actionClose={() => {
                                              console.log('display file stats modal, false');
                                              onToggleFileStats(selectedStatsFileId, false)
                                          }}/> : <></>
        );
    };

    const renderModal = () => {
        //console.log(folder);

        return (
            createDirDisplayModal ?
                <ModalSharepoint folder create parent_folder_path={folder?.path}
                                 parent_folder_id={folder?.folder_id}
                                 content_user_groups={folder?.user_groups} user_user_groups={user?.user_groups}
                                 all_user_groups={allUserGroups}
                                 actionClose={() => {
                                     console.log('display modal, false');
                                     setCreateDirDisplayModal(false)
                                 }} actionFolderCreatedSuccess={(folder) => {

                    onLoadFolderContent(folder);
                }}/> : updateDirDisplayModal ?
                    <ModalSharepoint folder update parent_folder_picture={folder?.picture}
                                     parent_folder_path={folder?.path}
                                     parent_folder_id={folder?.folder_id}
                                     actionClose={() => {
                                         console.log('display modal, false');
                                         setUpdateDirDisplayModal(false)
                                     }} actionFolderUpdatedSuccess={(folder) => {
                        onLoadFolderContent(folder);
                    }}/> : updateFileDisplayModal ? <ModalSharepoint file update
                                                                     selected_file={selectedFile}
                                                                     actionClose={() => {
                                                                         console.log('display modal, false');
                                                                         setUpdateFileDisplayModal(false)
                                                                         setSelectedFile(null);
                                                                     }} actionFileUpdatedSuccess={(file) => {
                        onLoadFolderContent(folder);
                    }}/> : <></>
        );
    };

    const renderToolbar = () => {
        let className = 'sharepoint toolbar-button sharepoint-button';
        let classNameEditSortingBtn = className;

        if (!folder || !folder.parent_folder_id) {
            className += ' disabled';
        }
        if (editSortOrder) {
            classNameEditSortingBtn += ' selected';
        }
        let isUserGroupAdmin = isUserAdminInCurrentFolder(userCurrentFolderUserRole, user?.username);
        if (!user) {
            return <></>
        }
        if (!folder && (!files || files.length === 0) && (!folders || folders.length === 0) && user.username === 'plu.ceniki@gmail.com') {
            isUserGroupAdmin = true;
        }

        return (
            <div className={'sharepoint toolbar'}>
                <div className={'sharepoint container-column-2'} style={{paddingRight: 0}}>
                    <div className={'column-2'}>
                        <div className={'container-row-2'}>
                            <div className={'row-2'}>
                                <div className={'toolbar-actions'} style={{justifyContent: 'flex-start'}}>
                                    <div className={className}
                                         onClick={() => folder?.parent_folder_id ? onNavigateToFolder(folder, true) : {}}>
                                        <IoIcons.IoMdReturnLeft className={'sharepoint icon toolbar-icon'}/>
                                    </div>
                                    <div className={'sharepoint toolbar-button sharepoint-button'}
                                         style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                         onClick={() => isUserGroupAdmin && setCreateDirDisplayModal(true)}>
                                        <BiIcons.BiFolderPlus className={'sharepoint icon toolbar-icon'}/>
                                    </div>
                                    <FileUploadSharepoint
                                        style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                        handleFile={(file) => {
                                            setFile(file);
                                            setFileName(file.name);

                                            onUploadFile(file).then(() => {
                                                console.log("done uploading:", file.name);
                                            })
                                        }}/>
                                    <div className={'sharepoint toolbar-button sharepoint-button'}
                                         style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                         onClick={() => isUserGroupAdmin && setUpdateDirDisplayModal(true)}>
                                        <IconEdit style={{margin: '0px'}}
                                                  className={'sharepoint icon toolbar-icon edit'} width={'20px'}
                                                  height={'20px'}/>
                                    </div>
                                    <div className={classNameEditSortingBtn}
                                         style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                         onClick={() => isUserGroupAdmin && toggleEditSortOrder(!editSortOrder)}>
                                        <IconSort style={{margin: '0px'}}
                                                  className={'sharepoint icon toolbar-icon sort'} width={'22px'}
                                                  height={'22px'}/>
                                    </div>
                                    <div className={'sharepoint toolbar-button button-delete sharepoint-button'}
                                         style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                         onClick={() => isUserGroupAdmin && onDeleteFolder(folder.folder_id)}>
                                        <BiIcons.BiTrash className={'sharepoint icon toolbar-icon'}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'column-2'} style={{marginLeft: '30px'}}>
                        <div className={'sharepoint search-container'}>
                            <Input onSubmit={() => {
                            }}
                                   onChange={setNameInput}
                                   placeholder={'Išči po gradivu..'}
                                   white
                                   icon
                            />
                        </div>
                    </div>
                </div>
                <div className={'sharepoint container-column-2'} style={{padding: 0, zIndex: 1}}>
                    <div className={'column-2'}>
                        <div className={'mime-filter-container'}>
                            <div
                                className={mimeFilter === MIME_OPTIONS.FOTO ? 'mime-filter selected' : 'mime-filter'}
                                onClick={() => setMimeFilter(mimeFilter === MIME_OPTIONS.FOTO ? null : MIME_OPTIONS.FOTO)}>
                                <IconFoto style={{margin: '0px'}}
                                          className={'sharepoint icon toolbar-icon mime-foto'}
                                          width={'20px'} height={'20px'}/>
                            </div>
                            <div
                                className={mimeFilter === MIME_OPTIONS.VIDEO ? 'mime-filter selected' : 'mime-filter'}
                                onClick={() => setMimeFilter(mimeFilter === MIME_OPTIONS.VIDEO ? null : MIME_OPTIONS.VIDEO)}>
                                <IconVideo style={{margin: '0px'}}
                                           className={'sharepoint icon toolbar-icon mime-video'}
                                           width={'20px'} height={'20px'}/>
                            </div>
                            <div
                                className={mimeFilter === MIME_OPTIONS.PDF ? 'mime-filter selected' : 'mime-filter'}
                                onClick={() => setMimeFilter(mimeFilter === MIME_OPTIONS.PDF ? null : MIME_OPTIONS.PDF)}>
                                <IconPdf style={{margin: '0px'}} className={'sharepoint icon toolbar-icon mime-pdf'}
                                         width={'20px'} height={'20px'}/>
                            </div>
                            <div
                                className={mimeFilter === MIME_OPTIONS.URL ? 'mime-filter selected' : 'mime-filter'}
                                onClick={() => setMimeFilter(mimeFilter === MIME_OPTIONS.URL ? null : MIME_OPTIONS.URL)}>
                                <IconUrl style={{margin: '0px'}} className={'sharepoint icon toolbar-icon mime-url'}
                                         width={'20px'} height={'20px'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'column-2'} style={{paddingRight: '60px'}}>
                        <DropdownFilter onChange={setSelectedFilter}
                                        options={['starejši naprej', 'novejši naprej', 'po abecedi', 'poljubno']}
                                        placeholder={'Filtriraj'}/>
                    </div>
                </div>

            </div>
        );
    }

    function Folder(props) {
        //  console.log(props.value);
        const item = props.value;

        let foundUserGroup = isUserMemberOfAnyUserGroup(user.user_groups, item.user_groups, allUserGroups, true, user?.username);
        let isSubscriber = isUserMemberOfAnySubscriberGroups(user.user_groups, allUserGroups);

        let imgClass = 'sharepoint img-folder';
        let rootFolderName = parseNameFromPath(folder?.path?.toLowerCase());

        console.log("NAME", rootFolderName);
        if (rootFolderName.includes("strategija")) { //strategije
            imgClass += ' strategije'
        }
        const displayFolder = (className) => {
            if (item.picture && item.picture !== '' && item.picture.length > 20) {
                return <>
                    <img className={imgClass} src={item.picture} alt="img"/>
                    {className.includes('sort-folder') &&
                        <BiIcons.BiSort className={'sharepoint icon sort-folder'}/>}
                </>
            } else {
                return <><p className={'p-file'} style={{
                    fontWeight: 'bold',
                    fontSize: '16px'
                }}>{parseMapCaretChars(parseNameFromPath(item?.path))}</p>
                    {className.includes('sort-folder') &&
                        <BiIcons.BiSort className={'sharepoint icon sort-folder'}/>}
                </>
            }
        }

        let className = 'sharepoint-folder';
        if (!foundUserGroup && !isSubscriber) {
            className += ' disabled'
        } else if (editSortOrder && selectedSortFolder && selectedSortFolder?.folder_id === item.folder_id) {
            className += ' sort-folder'
        }
        return (
            <div onClick={() => {
                !editSortOrder ? (foundUserGroup || isSubscriber) && onNavigateToFolder(item) : selectSortFolder(item);
            }} className={'row'} style={{left: `${sidebarWidth + 50}px`}}>
                <div className={className}>
                    {displayFolder(className)}
                </div>
            </div>
        );
    }

    function renderFolders(items) {
        const list = (items) => {
            if (!items) {
                return;
            }
            return (
                <div className={'item-list folders'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <Folder key={index} value={item}/>)}
                </div>
            );
        }

        return (
            <>
                <div className={'sharepoint container-folders'}>
                    {items && list(items)}
                </div>
            </>
        );
    }

    function File(props) {
        // console.log(props.value);
        const item = props.value;
        const isUserGroupAdmin = props.isUserGroupAdmin;
        const isUserGroupSubscriber = props.isUserGroupSubscriber;
        const isUserGroupCaretaker = props.isUserGroupCaretaker;
        const isSubscriber = props.isSubscriber;

        const shouldDisplay = !mimeFilter || parseMimeFilter(item?.mime_type) === mimeFilter;

        return (
            <div onClick={() => {
            }} className={'row'}
                 style={{left: `${sidebarWidth + 50}px`, display: shouldDisplay ? 'visible' : 'none'}}>
                <div className={'sharepoint row-2'}>
                    <div className={'sharepoint-file'}>
                        <p className={'p-file'}>{parseMapCaretChars(item?.name)}</p>
                        <div className={'sharepoint file-container-right'}>
                            <p className={'p-file'} style={{
                                paddingRight: '22px',
                                paddingLeft: '4px',
                                fontWeight: 'normal',
                                color: '#707070'
                            }}>{item?.mime_type}</p>

                            {user?.username.includes("petra.ojstersek") || user?.username.includes("plu.ceniki@gmail.com") || user?.username.includes("katarina.zuzek@heineken.com") || user?.username.includes("eva.becan2@heineken.com")?
                                <div className={'sharepoint-icon'} onClick={() => {
                                    onToggleFileStats(item.file_id, true)
                                }}>
                                    <MdIcons.MdComputer style={{marginTop: '4px'}} className={'icon'} width={'30px'}
                                                       height={'e0px'}/>
                                </div>
                                : <></>}

                            <div className={'sharepoint-icon'}
                                 style={{display: isSubscriber && !(isUserGroupAdmin || isUserGroupCaretaker) ? 'none' : 'flex'}}
                                 onClick={() => onDownloadFile(item.file_id)}>
                                <IconDownload style={{transform: 'rotateX(180deg)'}} className={'icon'}
                                              width={'18px'}
                                              height={'17px'}/>
                            </div>

                            <div className={'sharepoint-icon'} onClick={() => {
                                onPreviewFile(item.file_id)
                            }}>
                                <IconEye style={{margin: '0px'}} className={'icon'} width={'20px'} height={'19px'}/>
                            </div>
                            <div className={'sharepoint-icon'}
                                 style={{display: isSubscriber && !(isUserGroupAdmin || isUserGroupCaretaker) ? 'none' : 'flex'}}
                                 onClick={() => {
                                     onSendFileToUserEmail(item.file_id, user.username)
                                 }}>
                                <IconMail style={{margin: '0px'}} className={'icon'} width={'19px'}
                                          height={'19px'}/>
                            </div>
                            <div className={'sharepoint-icon gray'}
                                 style={{display: isUserGroupAdmin ? 'flex' : 'none', fill: colors.primary}}
                                 onClick={() => {
                                     setUpdateFileDisplayModal(true);
                                     setSelectedFile(item);
                                 }}>
                                <IconEdit style={{margin: '0px'}} className={'icon'} width={'20px'}
                                          height={'20px'}/>
                            </div>

                            <div className={'sharepoint-icon'} style={{display: isUserGroupAdmin ? 'flex' : 'none'}}
                                 onClick={() => isUserGroupAdmin && onDeleteFile(item.file_id)}>
                                <FaIcons.FaRegTrashAlt className={'sharepoint icon remove'}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }


    function renderFiles(items) {

        const list = (items) => {
            if (!items || !user || !folder || !allUserGroups) {
                return;
            }

            let isUserGroupAdmin = isUserAdminInCurrentFolder(userCurrentFolderUserRole, user?.username);
            let isUserGroupSubscriber = isUserSubscriberInCurrentFolder(userCurrentFolderUserRole);
            let isUserGroupCaretaker = isUserCaretakerInCurrentFolder(userCurrentFolderUserRole);
            let isSubscriber = isUserMemberOfAnySubscriberGroups(user.user_groups, allUserGroups);

            if (!isUserGroupAdmin && !isUserGroupSubscriber && !isUserGroupCaretaker && !isSubscriber) {
                return <></>
            }
            return (
                <div className={'item-list'} style={{left: `${sidebarWidth + 50}px`}}>
                    {items.map((item, index) => <File key={index} value={item} isUserGroupAdmin={isUserGroupAdmin}
                                                      isUserGroupSubscriber={isUserGroupSubscriber}
                                                      isSubscriber={isSubscriber}
                                                      isUserGroupCaretaker={isUserGroupCaretaker}/>)}
                </div>
            );
        }

        const renderColumns = (items) => {
            const ROW_HEIGHT = 50 + 2;
            //console.log(clientSize.y, items.length * ROW_HEIGHT);
            if (files && items.length > 0 && items.length * ROW_HEIGHT > clientSize.y / 3) {
                return (
                    <>
                        <div className={'column-2'}>
                            {items && list(items.slice(0, Math.ceil(files.length / 2)))}
                        </div>
                        <div className={'column-2'}>
                            {items && list(items.slice(-Math.floor(files.length / 2)))}
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className={'column-2'}>
                            {items && list(items)}
                        </div>
                        <div className={'column-2'}>
                        </div>
                    </>
                )
            }
        }

        return (
            <>
                <div className={'sharepoint files'}>
                    {items && list(items)}
                </div>
            </>
        );
    }

    return !isSessionValid(token) ? <Redirect to={PAGES.SHAREPOINT_LOGIN} push={false}/> :
        token && <div ref={ref}>
            <div className={'background-blurred'} style={{backgroundImage: `url(${background})`}}/>
            <Page relative sharepoint className={'sharepoint main'} style={{height: `${mainHeight}px`}}
                  onToggleSidebar={(shown) => {
                      if (shown) {
                          dispatch(setSidebarWidth(SIDEBAR_WIDTH))
                      } else {
                          dispatch(setSidebarWidth(0))
                      }
                  }}>
                <div className={'body'} style={{marginTop: '100px', paddingLeft: `${sidebarWidth}px`}}>
                    <Header sharepoint left={`${sidebarWidth + 50}px`} rerender={c}
                            path={`${folder ? folder.path : 'nalaganje..'}`}
                            title={`${folder ? parseNameFromPath(folder.path) : 'nalaganje..'}`}
                            folder={folder}
                            enable_edit={isUserAdminInCurrentFolder(userCurrentFolderUserRole, user?.username)}
                            edit_description={isUserAdminInCurrentFolder(userCurrentFolderUserRole, user?.username) && editDescription}
                            edit_description_action={setEditDescription}
                            sharepoint_folder_description={parseMapCaretChars(folder?.description ? folder.description : '')}
                            sharepoint_folder_description_content={renderCollapsableDirDesc}
                            sharepoint_folder_update={setFolder}
                    />
                    {/*console.log(c)*/}
                    {renderToolbar()}
                    {renderRootWelcomeBanner()}
                    {renderFolders(folders)}
                    {renderFiles(files)}
                </div>
            </Page>
            {renderModal()}
            {renderModalFileStats()}
            {renderDownloadProgress()}
            {renderModalInfo()}
        </div>
}

export default Sharepoint;